import axios from "axios";
import { authHeader } from "../helpers/auth-header";
import { config } from "../helpers/config";
import { LOGIN_PATH, PUBLIC_PATHS, UNAUTHORIZED } from "../store/Auth";

class HttpService {
  static Instance() {
    let ax = new axios.create({
      baseURL: config.apiUrl,
      timeout: 30000,
      headers: authHeader(),
    });

    ax.interceptors.request.use((config) => {
      let token = localStorage.getItem("APS-TOKEN");
      if (token) {
        config.headers.Authorization = "Bearer " + token;
      }
      if (
        config.headers["Content-Type"] &&
        config.headers["Content-Type"].startsWith("multipart")
      ) {
        config.timeout = 600000;
      }
      return config;
    });

    ax.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error && error.response && error.response.status === UNAUTHORIZED) {
          if (
            window.location.pathname &&
            !window.location.pathname.includes(LOGIN_PATH) &&
            !PUBLIC_PATHS.includes(window.location.pathname)
          ) {
            localStorage.removeItem("APS-TOKEN");
            localStorage.removeItem("prodGroup");
            localStorage.removeItem("userRole");
            localStorage.removeItem("version");
            window.location = "/login";
          }
        } else {
          return Promise.reject(error);
        }
      }
    );

    return ax;
  }
}

//https://gist.github.com/alfonmga/96474f6adb6ed8dee8bc8bf8627c0ae1
export default HttpService.Instance();
