import React, { createContext, useContext, useEffect, useState } from "react";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";

const TourContext = createContext();

export const TourProvider = ({ children }) => {
  const [isTourStarted, setIsTourStarted] = useState(false);

  const startTour = () => {
    setIsTourStarted(true);
  };

  const endTour = () => {
    setIsTourStarted(false);
  };

  return (
    <TourContext.Provider
      value={{
        isTourStarted,
        startTour,
        endTour,
        driver,
        isTourStarted,
      }}
    >
      {children}
    </TourContext.Provider>
  );
};

export const useTour = () => {
  const context = useContext(TourContext);
  if (!context) {
    throw new Error("useTour must be used within a TourProvider");
  }
  return context;
};
