import React, { createContext, useContext, useEffect, useState } from "react";

const LocalStorageContext = createContext();

export const useLocalStorage = () => {
  const context = useContext(LocalStorageContext);
  if (!context) {
    throw new Error(
      "useLocalStorage must be used within a LocalStorageProvider"
    );
  }
  return context;
};

export const LocalStorageProvider = ({ children }) => {
  const [storedValues, setStoredValues] = useState({});

  useEffect(() => {
    const keys = Object.keys(localStorage);

    const initialValues = keys.reduce((acc, key) => {
      const item = localStorage.getItem(key);
      try {
        acc[key] = JSON.parse(item);
      } catch (e) {
        acc[key] = item;
      }
      return acc;
    }, {});

    setStoredValues(initialValues);
  }, []);

  const setValueInLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
    setStoredValues((prev) => ({ ...prev, [key]: value }));
  };

  const getValueFromLocalStorage = (key) => {
    return storedValues[key];
  };

  return (
    <LocalStorageContext.Provider
      value={{ getValueFromLocalStorage, setValueInLocalStorage }}
    >
      {children}
    </LocalStorageContext.Provider>
  );
};
