import axios from "../axios";
class AuthServices {
  static Instance() {
    return new AuthServices();
  }

  getLoginFunction(request) {
    return axios.post("/api/user/authenticate", request);
  }

  getLoggedInUserData() {
    return axios.get("/api/user/self/auth");
  }

  getUserPrimaryRole() {
    return axios.get("/api/user/fetch/primary/role");
  }

  getOrganizationLogo() {
    return axios.get("/api/user/logo");
  }
}
export default AuthServices.Instance();
