import { ConfigProvider, theme } from "antd";
import React from "react";
import APSLayout from "./Layout/APSLayout";
import { useDarkMode } from "./Providers/DarkModeContext";

function App() {
  const { isDarkMode } = useDarkMode();
  const { darkAlgorithm, defaultAlgorithm } = theme;

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: isDarkMode ? "#4096ff" : "#1677ff",
            fontFamily: "Inter",
          },
          algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
          components: {
            Slider: {
              trackBg: "#91caff",
            },
            Statistic: {
              contentFontSize: 15,
            },
            Popconfirm: {
              colorBgContainer: "#fff",
            },
          },
        }}
        componentSize="small"
      >
        <APSLayout />
      </ConfigProvider>
    </>
  );
}

export default App;
