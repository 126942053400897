import axios from "../axios";
class ManageUserService {
  static Instance() {
    return new ManageUserService();
  }

  getUserServiceCategory() {
    return axios.get("api/admin/user/categories");
  }

  saveUserServicesData(payload) {
    return axios.post("api/user/user", payload);
  }

  getStaffServicesData(ID, payload) {
    return axios.post(`api/admin/user/list?Type=${ID}`, payload);
  }

  getUserRoleData() {
    return axios.get("api/admin/role/list");
  }

  updateUserRoleData(payload) {
    return axios.put(`api/admin/user`, payload);
  }
  getUserCount() {
    return axios.get("api/admin/user/count");
  }
  getUserSelfDetails() {
    return axios.get("api/user/self/detail");
  }

  getUserRoleList(userId) {
    return axios.get(`api/admin/role/list/worker`);
  }

  updateUserRole({ roleID }) {
    return axios.post(`api/admin/user/role/primary?roleID=${roleID}`, {});
  }
}
export default ManageUserService.Instance();
