import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Drawer, Form, message, Row, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import CustomButton from "../Common/CustomButton";
import ManageUserService from "../services/User/ManageUserService";

const WorkerRoleChange = ({ open, onClose }) => {
  const [rolesList, setRolesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [form] = useForm();

  const fetchUserDetails = async () => {
    try {
      const res = await ManageUserService.getUserRoleList();

      const filteredList = res?.data?.map((i) => ({
        label: i.name,
        value: i.id,
      }));

      setRolesList(filteredList || []);
    } catch (error) {
      error?.response?.data?.message
        ? message.error(error?.response?.data?.message)
        : message.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchUserDetails();
    }
  }, [open]);

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const onFinish = async (values) => {
    setIsSubmitting(true);

    try {
      await ManageUserService.updateUserRole(values);

      message.success("Updated role successfully!");

      window.location.reload();
    } catch (error) {
      console.log(error);
      message.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went wrong!"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const filterOption = (input, option) =>
    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  return (
    <Drawer open={open} onClose={handleClose} title="Change current role">
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="roleID"
          label="Select new role"
          rules={[
            {
              required: true,
              message: "Select role",
            },
          ]}
        >
          <Select
            placeholder="Select role"
            loading={isLoading}
            size="middle"
            options={rolesList}
            showSearch
            filterOption={filterOption}
          />
        </Form.Item>

        <Row className="justify-end w-full gap-3 mt-10">
          <CustomButton
            type="primary"
            loading={isSubmitting}
            htmlType="submit"
            icon={<CheckOutlined />}
          >
            Submit
          </CustomButton>

          <CustomButton
            type="cancel"
            onClick={handleClose}
            icon={<CloseOutlined />}
          >
            Cancel
          </CustomButton>
        </Row>
      </Form>
    </Drawer>
  );
};

export default WorkerRoleChange;
