import {
  AuditOutlined,
  BankOutlined,
  FileAddOutlined,
  HomeOutlined,
  IdcardOutlined,
  LockOutlined,
  RiseOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  TeamOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import { Layout, Menu, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { AiOutlineSchedule } from "react-icons/ai";
import { BiMessageDetail } from "react-icons/bi";
import { FaRegUser } from "react-icons/fa";
import { HiOutlineTicket } from "react-icons/hi";
import { IoList } from "react-icons/io5";
import { LiaWindowRestore } from "react-icons/lia";
import {
  MdChevronLeft,
  MdChevronRight,
  MdManageHistory,
  MdOutlineCreate,
  MdOutlinePhonelinkSetup,
  MdSettings,
} from "react-icons/md";
import { RiUserSettingsLine } from "react-icons/ri";
import { TbSettingsCog } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../Common/CustomButton";
import Navbar from "../Navbar/Navbar";
import { AuthContext } from "../Providers/AuthProvider";
import { useDarkMode } from "../Providers/DarkModeContext";
import { useScreenSize } from "../Providers/ScreenSizeProvider";
import RoutesConfig from "../config/routes.config";
import "../custom.css";

const { Sider, Content } = Layout;

const APSLayout = () => {
  const {
    userDetails,
    refetchData,
    primaryColor,
    secondaryColor,
    tertiaryColor,
    quaternaryColor,
  } = useContext(AuthContext);

  const currentScreenSize = useScreenSize();
  const location = useLocation();
  const navigate = useNavigate();

  const { isDarkMode } = useDarkMode();
  const [collapsed, setCollapsed] = useState(
    currentScreenSize > 1080 ? false : true
  );
  const [currMenu, setCurrMenu] = useState([]);
  let token = localStorage.getItem("APS-TOKEN");

  const root = document.documentElement;

  root.style.setProperty("--primary-color", primaryColor);
  root.style.setProperty("--secondary-color", secondaryColor);
  root.style.setProperty("--tertiary-color", tertiaryColor);
  root.style.setProperty("--quaternary-color", quaternaryColor);

  const pathsForHidingNavbar = ["/product-group/configure-group-status"];

  const shouldShowSidebar =
    userDetails &&
    currMenu &&
    currMenu.length > 0 &&
    (currMenu.length > 1 ||
      (currMenu[0].children && currMenu[0].children.length > 1));

  useEffect(() => {
    const mapChildren = (items) => {
      return items?.map((item) => ({
        ...item,
        children:
          item.children && item.children.length > 0
            ? mapChildren(item.children)
            : null,
      }));
    };
    setCurrMenu(mapChildren(userDetails?.menu?.items));
  }, [userDetails]);

  const handleMenuClick = ({ key }) => {
    navigate(key);
  };

  const handleLogoutFunction = () => {
    localStorage.removeItem("APS-TOKEN");
    localStorage.removeItem("prodGroup");
    localStorage.removeItem("useRole");
    message.success("Logged out successfully");
    navigate("/login");
    refetchData();
  };

  const iconMap = {
    HomeOutlined: <HomeOutlined />,
    TeamOutlined: <TeamOutlined />,
    SettingOutlined: <SettingOutlined />,
    ShoppingCartOutlined: <ShoppingCartOutlined />,
    RiseOutlined: <RiseOutlined />,
    ToolOutlined: <ToolOutlined />,
    BankOutlined: <BankOutlined />,
    IdcardOutlined: <IdcardOutlined />,
    AiOutlineSchedule: <AiOutlineSchedule />,
    BiMessageDetail: <BiMessageDetail />,
    FaRegUser: <FaRegUser />,
    IoList: <IoList />,
    LiaWindowRestore: <LiaWindowRestore />,
    MdManageHistory: <MdManageHistory />,
    MdOutlineCreate: <MdOutlineCreate />,
    MdOutlinePhonelinkSetup: <MdOutlinePhonelinkSetup />,
    MdSettings: <MdSettings />,
    FileAddOutlined: <FileAddOutlined />,
    RiUserSettingsLine: <RiUserSettingsLine />,
    LockOutlined: <LockOutlined />,
    TbSettingsCog: <TbSettingsCog />,
    HiOutlineTicket: <HiOutlineTicket />,
    AuditOutlined:<AuditOutlined />,
  };

  if (userDetails && pathsForHidingNavbar.includes(location.pathname)) {
    return <RoutesConfig />;
  }

  return (
    <>
      <Layout style={{ minHeight: "100vh", background: "none" }}>
        {userDetails && !pathsForHidingNavbar.includes(location.pathname) && (
          <Navbar setCollapsed={setCollapsed} collapsed={collapsed} />
        )}

        <Layout
          style={
            !userDetails
              ? {}
              : shouldShowSidebar
              ? {
                  marginTop: "50px",
                  marginLeft: collapsed ? "80px" : "200px",
                  background: "none",
                }
              : { marginTop: "50px", background: "none" }
          }
        >
          {userDetails &&
            shouldShowSidebar &&
            !pathsForHidingNavbar.includes(location.pathname) && (
              <Sider
                width={205}
                style={{
                  position: "fixed",
                  height: "100%",
                  left: 0,
                  top: "50px",
                  zIndex: 999,
                  background: "none",
                }}
                className={` border-r-2  ${
                  isDarkMode
                    ? "!bg-[#1b1d1e] border-gray-600"
                    : "!bg-gray-50 border-gray-200"
                } border-opacity-50`}
                trigger={null}
                collapsible
                collapsed={collapsed}
              >
                <div
                  className={`absolute -right-4 z-[1200] top-4 ${
                    isDarkMode
                      ? "!bg-[#1b1d1e] border-gray-600"
                      : "bg-white border-gray-200"
                  }  border-2 border-opacity-70 rounded-full`}
                >
                  <CustomButton
                    shape="circle"
                    size="small"
                    type="link"
                    className="items-center justify-center hidden text-sm text-center lg:flex"
                    onClick={() => setCollapsed(!collapsed)}
                  >
                    {collapsed ? (
                      <MdChevronRight size={20} />
                    ) : (
                      <MdChevronLeft size={20} />
                    )}
                  </CustomButton>
                </div>

                <Menu
                  style={{
                    height: "100%",
                    paddingTop: "1.8rem",
                    position: "sticky",
                  }}
                  className={` ${
                    isDarkMode ? "!bg-[#1b1d1e]" : "!bg-gray-50"
                  } border-r-2 border-gray-200 border-opacity-50 w-fit mx-auto ${
                    collapsed && "px-4"
                  }`}
                  onClick={handleMenuClick}
                  selectedKeys={[window.location.pathname]}
                  items={currMenu?.map((menu_item) => {
                    const updatedItem = { ...menu_item };
                    if (menu_item.icon) {
                      updatedItem.icon = iconMap[menu_item.icon];
                    }
                    if (menu_item.children) {
                      updatedItem.children = menu_item.children.map(
                        (child) => ({
                          ...child,
                          icon: iconMap[child.icon],
                        })
                      );
                    }
                    return updatedItem;
                  })}
                />
              </Sider>
            )}

          <Content
            style={{
              overflow: "initial",
              background: "",
            }}
            className={` ${isDarkMode ? "!bg-[#1b1d1e]" : "!bg-white"} ${
              token ? "px-6" : ""
            }`}
          >
            <RoutesConfig />
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
export default APSLayout;
