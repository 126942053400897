import {
  LogoutOutlined,
  SecurityScanOutlined,
  UserAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Dropdown, Image, message, Space } from "antd";
import React, { useContext, useState } from "react";
import { FaCaretDown } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import CustomButton from "../Common/CustomButton";
import DarkModeToggler from "../Common/DarkModeToggler";
import WorkerRoleChange from "../components/WorkerRoleChange";
import { AuthContext } from "../Providers/AuthProvider";
import { useDarkMode } from "../Providers/DarkModeContext";
import { getDarkModeColor } from "../utils/darkModeHelper";

const Navbar = ({ setCollapsed, collapsed }) => {
  const { userDetails, organizationLogoUrl, refetchData, userPrimaryRole } =
    useContext(AuthContext);

  const [roleDrawer, setRoleDrawer] = useState(false);

  let isAdmin = userPrimaryRole?.toLowerCase()?.includes("admin");

  const { isDarkMode, toggleDarkMode } = useDarkMode();

  const navigate = useNavigate();

  const items = [
    {
      label: "Profile",
      key: "/user/self/detail",
      icon: <UserAddOutlined />,
      onClick: () => {
        navigate("/user/self/detail");
      },
    },
    {
      label: "Change role",
      key: "role-change",
      icon: <SecurityScanOutlined />,
      onClick: () => {
        setRoleDrawer(true);
      },
      hidden: !userPrimaryRole.includes("_worker"),
    },
    {
      label: "Logout",
      key: "3",
      icon: <LogoutOutlined />,
      onClick: async () => {
        localStorage.removeItem("APS-TOKEN");
        localStorage.removeItem("prodGroup");
        localStorage.removeItem("userRole");
        localStorage.removeItem("version");
        message.success("Logout Successfully");
        navigate("/login");
        refetchData();
      },
    },
  ].filter((i) => !i.hidden);

  const handleNavigateHome = () => {
    const firstMenuItem = userDetails?.menu?.items[0];

    const firstItemChild =
      firstMenuItem?.children && firstMenuItem.children.length > 0
        ? firstMenuItem.children[0]
        : null;

    const targetKey = firstItemChild ? firstItemChild?.key : firstMenuItem?.key;
    navigate(targetKey);
  };

  return (
    <div>
      <div
        className={`fixed z-[1000] top-0 left-0 flex justify-between w-full ${getDarkModeColor(
          isDarkMode,
          "bg-gradient-to-tr border-gray-200 from-primary to-secondary"
        )} p-1 py-3 border-b-2  border-opacity-50  h-14`}
      >
        <div className="flex flex-1 gap-2 ml-4 item-center w-fit">
          <Image
            preview={false}
            height={"30px"}
            src={"./new-logo.png"}
            alt="logo"
            className="object-contain cursor-pointer"
            onClick={() => handleNavigateHome()}
          />

          <Image
            preview={false}
            height={"30px"}
            width={"140px"}
            src={organizationLogoUrl}
            alt="client logo"
            className="object-contain ml-8"
          />
        </div>

        <div className="flex items-center justify-center gap-4 mr-2 w-fit">
          {isAdmin && (
            <CustomButton
              type="link"
              onClick={() => window.location.replace("take-a-tour")}
            >
              Take a tour
            </CustomButton>
          )}

          <DarkModeToggler />
        </div>

        <Space className="text-neutral-100">
          {userDetails?.firstName && (
            <Dropdown
              menu={{
                items,
              }}
              trigger={["click"]}
            >
              <CustomButton
                style={{ color: "neutral" }}
                size="middle"
                icon={<UserOutlined style={{ color: "neutral" }} />}
                type="text"
                className="flex items-center gap-1 font-semibold tracking-widest"
              >
                {userDetails.firstName
                  ? userDetails.firstName.toUpperCase()
                  : ""}
                <FaCaretDown />
              </CustomButton>
            </Dropdown>
          )}
        </Space>
      </div>

      <WorkerRoleChange
        open={roleDrawer}
        onClose={() => {
          setRoleDrawer(false);
        }}
      />
    </div>
  );
};

export default Navbar;
