import React, { createContext, useContext, useEffect, useState } from "react";

const DarkModeContext = createContext();

export const DarkModeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const darkModeValue = localStorage.getItem("darkMode");
    return darkModeValue ? JSON.parse(darkModeValue) : null;
  });

  useEffect(() => {
    if (isDarkMode === null) {
      const systemDarkMode = window.matchMedia("(prefers-color-scheme: dark)");

      if (systemDarkMode.matches) {
        localStorage.setItem("darkMode", "true");
        setIsDarkMode(true);
      }

      systemDarkMode.addEventListener("change", (evt) =>
        setIsDarkMode(evt.matches)
      );
    }
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    localStorage.setItem("darkMode", `${!isDarkMode}`);
    setIsDarkMode((prevMode) => !prevMode);
  };

  return (
    <DarkModeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
      {children}
    </DarkModeContext.Provider>
  );
};

export const useDarkMode = () => {
  const context = useContext(DarkModeContext);
  if (!context) {
    throw new Error("useDarkMode must be used within a DarkModeProvider");
  }
  return context;
};
