import { Switch } from "antd";
import React from "react";
import { FaMoon, FaSun } from "react-icons/fa";
import { useDarkMode } from "../Providers/DarkModeContext";
import { getDarkModeColor } from "../utils/darkModeHelper";

const DarkModeToggler = () => {
  const { isDarkMode, toggleDarkMode } = useDarkMode();

  return (
    <Switch
      checked={isDarkMode}
      className={`${getDarkModeColor(
        isDarkMode,
        "!bg-gray-900",
        "!bg-gray-700"
      )}`}
      size="default"
      style={isDarkMode && { border: "1px solid gray" }}
      onChange={toggleDarkMode}
      checkedChildren={
        <FaMoon color="yellow" size={15} className="mt-[3px] -ml-1" />
      }
      unCheckedChildren={
        <FaSun color="yellow" size={17} className="mt-[6px] ml-1 -mr-[3px]" />
      }
    />
  );
};

export default DarkModeToggler;
